import { Dialog, useForm } from '@alejosdev/components'
import MDEditor from '@uiw/react-md-editor'
import { useSession } from 'hooks'

interface EditActivityInProgressProps {
    onClose?: () => void
    open?: boolean
}

const EditActivityInProgress: React.FC<EditActivityInProgressProps> = ({ onClose, open }) => {
    const { session, setKey } = useSession()
    const { description } = session?.activities?.activityInProgress || {}
    const [, form, { changeForm }] = useForm({
        defaultValues: {
            description: description
        },
        fields: {
            description: {
                label: ''
            }
        }
    })

    const handleAccept = () => {
        setKey('activities', {
            ...session?.activities,
            activityInProgress: {
                ...session?.activities?.activityInProgress,
                description: form.description
            }
        })
        onClose?.()
    }

    return (
        <Dialog title="Edit activity" open={open} onAccept={handleAccept} onClose={onClose}>
            <MDEditor
                preview="edit"
                value={(form.description || '') as string}
                onChange={(newValue) => changeForm({ description: newValue })}
                commands={[]}
            />
        </Dialog>
    )
}

export default EditActivityInProgress
