import React, { useCallback, useMemo, useState } from 'react'
import { useDateUtils, useSession } from 'hooks'
import { Icon, IconButton } from '@alejosdev/components'
import RenderTimer from './RenderTimer'
import { ActivityFollowUpFormType } from 'types/form-types/activities.form-types'
import moment from 'moment'
import RenderResume from './RenderResume'
import styles from './activity-in-progress.module.scss'
import useGetActivities from 'graphql/use-get-activities'
import { getContrastColor } from 'utils'
import { Dialog } from 'components/dialogs'
import cs from 'classnames'
import EditActivityInProgress from './edit-activity-in-progress/EditActivityInProgress'

interface ActivityInProgressProps {}

const ActivityInProgress: React.FC<ActivityInProgressProps> = () => {
    const { session, setAllKeys } = useSession()
    const [maximized, setMaximized] = useState(true)
    const [openEdit, setOpenEdit] = useState(false)
    const [resume, setResume] = useState<ActivityFollowUpFormType | null>()
    const activity = useMemo(
        () => session?.activities?.activityInProgress,
        [session?.activities?.activityInProgress]
    )
    const { getDateDiff } = useDateUtils()
    const [result, loading] = useGetActivities({ variables: { id: activity?.activity_id } })
    const [activityData] = result || []
    const handleClear = useCallback(() => {
        setAllKeys({
            activities: {
                ...session.activities,
                inProgress: false,
                activityInProgress: null
            }
        } as SessionType)
    }, [setAllKeys, session.activities])

    const handleSaved = useCallback(() => {
        setResume(null)
        handleClear()
    }, [handleClear])

    const handleFinish = useCallback(() => {
        if (!activity) return false
        const startedAt = `${activity.date} ${activity.started_date}:00`
        const spentTime = getDateDiff({
            startDate: startedAt,
            format: 'YYYY-MM-DD HH:mm:ss',
            endDate: moment().format('YYYY-MM-DD HH:mm:ss'),
            diffType: 'minute'
        })
        setResume({
            ...activity,
            started_date: startedAt,
            time_spent: parseInt(`${spentTime}`, 10)
        })
    }, [activity, getDateDiff])

    if (!activity) {
        return null
    }
    const { started_date, time_spent, date } = activity

    return (
        <>
            <div
                className={cs(styles.root, {
                    [styles.minimized]: !maximized
                })}
            >
                <div className={styles.header}>
                    {Boolean(activityData?.category) && (
                        <div
                            className={cs(styles.iconWrapper)}
                            style={
                                {
                                    '--icon-bg-color': activityData?.category?.color,
                                    color: getContrastColor(activityData?.category?.color)
                                } as React.CSSProperties
                            }
                        >
                            <Icon icon={activityData?.category?.icon} />
                        </div>
                    )}
                    <div className={styles.headerContent}>
                        {maximized && <p>{activityData?.category?.name}</p>}
                        <span>
                            {!maximized
                                ? `${activity.description?.substring(0, 20)}...`
                                : activity.description?.substring(0, 30)}
                        </span>
                    </div>
                    <div className={styles.windowControls}>
                        <IconButton
                            size="sm"
                            icon={maximized ? 'chevron-down' : 'chevron-up'}
                            onClick={() => setMaximized(!maximized)}
                        />
                        {maximized && <IconButton size="sm" icon="times" onClick={handleClear} />}
                    </div>
                </div>
                {loading && <label>Loading...</label>}
                <RenderTimer
                    started_date={`${date} ${started_date}:00`}
                    estimated={parseInt(`${time_spent}`, 10)}
                    maximized={maximized}
                />
                {maximized && (
                    <div className="flex justify-center">
                        {/* <IconButton
                        className="mr-2"
                        variant="danger"
                        icon="times"
                        size="sm"
                    /> */}
                        {/* <Button icon="check" variant="success" onClick={handleFinish}></Button> */}
                        <IconButton
                            icon="edit"
                            variant={'primary'}
                            className="mr-2"
                            size="sm"
                            onClick={() => setOpenEdit(true)}
                        />
                        <IconButton
                            icon="check"
                            variant={'success'}
                            onClick={handleFinish}
                            size="sm"
                        />
                    </div>
                )}
            </div>
            {openEdit && (
                <EditActivityInProgress open={openEdit} onClose={() => setOpenEdit(false)} />
            )}
            {Boolean(resume) && (
                <Dialog open onClose={() => setResume(null)}>
                    <RenderResume resume={resume} onSaved={handleSaved} />
                </Dialog>
            )}
        </>
    )
}

export default ActivityInProgress
